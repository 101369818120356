import React, { cloneElement } from 'react';

import { ProductsCarouselV3 } from '@mshops-components-library/products-carousel';
import { TabWithItems } from '@mshops-components-library/tab-with-items';

import Wrapper from './wrapper';
import withTracks from '../../adapters/withTracks';
import { useRenderContext } from '../../pages/home/context';


const ProductsCarouselWrapper = Wrapper(withTracks(ProductsCarouselV3));

const EnhancedProductsCarousel = (props) => {
  const { children, variation, itemsPerRow } = props;
  const { theme, withCardAction } = useRenderContext();

  return (
    <ProductsCarouselWrapper {...props} componentType="tabbed_carrousel">
      <TabWithItems {...props} theme={theme} with_card_action={withCardAction}>
        {children.map((child) => (
          cloneElement(child, {
            ...child.props,
            ...props,
            with_card_action: withCardAction,
            itemsPerRow: itemsPerRow || variation,
          })
        ))}
      </TabWithItems>
    </ProductsCarouselWrapper>
  );
};

export default EnhancedProductsCarousel;
